import cx from 'classnames'
import { useEffect, useState } from 'react'
import { wrap } from 'martha'

let index = -1
const colors = ['bg-peach-orange', 'bg-bubblegum-pink', 'bg-malibu-blue']

export default function Overline({ as: Component = 'h3', className, children, underline = true, underlineColor='' }) {
  const [color, setColor] = useState()

  useEffect(() => {
    if (underline && !underlineColor) {
      index += 1
      setColor([colors[wrap(index, colors.length)]])
      return () => {
        index -= 1
      }
    }
    if (underline && underlineColor) {
      setColor([underlineColor])
    }
  }, [colors, wrap, underline])

  return (
    <Component
      className={cx(
        'inline-block text-12 s:text-15 font-medium uppercase tracking-2 leading-100 relative',
        className,
      )}
    >
      {children}
      {underline ? (
        <div className={cx('absolute -bottom-5 inset-x-0 h-1 rounded-pill', color)}></div>
      ) : null}
    </Component>
  )
}
